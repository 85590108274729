document.addEventListener('turbolinks:load', () => {

  Rails.refreshCSRFTokens();

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        if($target) {
          $target.classList.toggle('is-active');
        }
      });
    });
  }

  if (typeof gtag === "function") {
    if(window.location.href.match(/\/category\//)) {
      gtag('event', 'view_category', {'category' : window.location.href.split('/').slice(-1)});
    } else if(window.location.href.match(/\/product\//)) {
      gtag('event', 'view_item', {'item' : window.location.href.split('/').slice(-1)});
    } else if(window.location.href.match(/\/page\//)) {
      gtag('event', 'view_page', {'page' : window.location.href.split('/').slice(-1)});
    } else if(window.location.href.match(/\/signin/)) {
      gtag('event', 'login', {});
    }

    document.querySelectorAll("a").forEach((link) => {
      let text = link.innerText.trim();
      if(text != '') {
        text = text.replace(" ", "_");

        link.addEventListener('click', () => {
          gtag('event', 'click', {'event_category' : 'e-commerce', 'event_label' : text});
        })
      }
    });
  }

  // Dropdowns
  var dropdowns = document.querySelectorAll(".dropdown:not(.is-hoverable), .navbar-item.has-dropdown:not(.is-hoverable)");

  if (dropdowns.length > 0) {
    dropdowns.forEach(function (el) {
      el.addEventListener("click", function (event) {
        event.stopPropagation();
        el.classList.toggle("is-active");
      });
    });

    document.addEventListener("click", function (event) {
      dropdowns.forEach(function (el) {
        el.classList.remove("is-active");
      });
    });
  }

  document.querySelectorAll(".trix-content").forEach((div, i) => {
    div.querySelectorAll('a').forEach((a, i) => {
      let domain = (new URL(a.href));
      if(domain.origin != window.location.origin) {
        a.setAttribute('target', '_blank');
      }
    });
  });

  document.querySelectorAll(".hero .container h2").forEach((h2, i) => {
    h2.classList.add('subtitle', 'is-size-3')
  });
  document.querySelectorAll(".hero .container h3").forEach((h3, i) => {
    h3.classList.add('subtitle', 'is-size-4')
  });

});

export function fadeOut(el, timeout = 2000) {
  setTimeout(() => {
    var fadeEffect = setInterval(function () {
      if (!el.style.opacity) {
        el.style.opacity = 1;
      }
      if (el.style.opacity > 0) {
        el.style.opacity -= 0.1;
      } else {
        clearInterval(fadeEffect);
        el.classList.add('is-hidden');
        el.style.opacity = 1;
      }
    }, 50);
  }, timeout);

}
